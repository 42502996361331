export const FirebaseConfig = {
	"projectId": "nantes1taxi",
	"appId": "1:539588685736:web:9d13ef2afbb1bac4a2379d",
	"databaseURL": "https://nantes1taxi-default-rtdb.firebaseio.com",
	"storageBucket": "nantes1taxi.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBkOP-BsYV2D9QzVnkshXmkCTKVAPMVLDQ",
	"authDomain": "nantes1taxi.firebaseapp.com",
	"messagingSenderId": "539588685736",
	"measurementId": "G-KFFLMY67L2"
};